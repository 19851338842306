export const GalleryMOU = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Gallery/MOU_1.webp",
        description: "We are glad to announce that we have signed MOU with GOOD WORD PUBLIC SCHOOL for inaugural of our MH Intellect and Aeroclub at their premises on 16/03/2024."
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Gallery/MOU_2.webp",
        description: "We are humbled to share that We have been associated with Sci-fi Labs as our exclusive Manufacturing and development partner since 11/03/2024."
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Gallery/MOU_3.webp",
        description: "We are happy to announce that we are associating hands with KRM GROUP of SCHOOLS for initiating our MH Intellect and Aeroclub at their schools on 12/03/2024."
    }

]