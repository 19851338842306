import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'
import { BoardMembers, OurGovernanceData } from '../../../Components/Our Governance/OurGovernanceData'

export default function OurGovernance() {
    return (
        <div className='text-center py-10'>
            <div className='text-center py-10'>
                <FadeInSectionDownToUp>
                    <p className='pb-5 text-3xl font-semibold'>
                        WE ARE MH COCKPIT
                    </p>
                    <p className='px-96 xl:px-96 sm:px-20 max-sm:px-10 text-lg'>
                        As an emerging leader in the Global Aviation training sector, We deliver
                        the most innovative solutions with the aim to create world class professionals
                        for a sustainable Aviation industry.
                    </p>
                    <h1 className='pt-20 text-3xl font-semibold'>
                        <span className='font-normal text-gray-600'>OUR</span> GOVERNANCE.
                    </h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 max-sm:grid-cols-1 px-20 max-sm:px-10 gap-5'>
                {OurGovernanceData.map((image, index) => (
                    <FadeInSectionDownToUp>
                        <div key={index} className='shadow-lg rounded-3xl hover:scale-105 transition-transform duration-700 ease-in-out'>
                            <img src={image.src} alt='Our Governance' className='rounded-t-3xl' />
                            <div className='py-2'>
                                <p>{image.name}</p>
                                <p>{image.role}</p>
                            </div>
                        </div>
                    </FadeInSectionDownToUp>
                ))}
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <h1 className='py-20 text-3xl font-semibold'>
                        Board of Advisors
                    </h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 max-sm:grid-cols-1 px-20 max-sm:px-10 gap-5'>
                {BoardMembers.map((image, index) => (
                    <FadeInSectionDownToUp>
                        <div key={index} className='shadow-lg rounded-3xl hover:scale-105 transition-transform duration-700 ease-in-out'>
                            <img src={image.src} alt='Our Governance' className='rounded-t-3xl' />
                            <p className='py-2'>{image.name}</p>
                        </div>
                    </FadeInSectionDownToUp>
                ))}
            </div>
        </div>
    )
}
