import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
import { useNavigate } from 'react-router-dom';

export default function CPL() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    const navigate = useNavigate();

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/WEBSITE%20CONTENT%20CPL%20VIDEO%20COVER.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>Commercial Pilot <span className='text-orange-500'>License.</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                                Take your passion to new heights as you progress beyond private flying. Our CPL program equips you with the skills to operate aircraft for compensation. From advanced flight maneuvers to precise navigation, our training covers it all. Requirements include a Private Pilot License, a minimum number of flight hours, and successful completion of ground school modules.
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-3xl max-sm:text-2xl font-semibold pt-10'>
                                Multi-Engine Endorsement
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                                Elevate your capabilities with our Multi-Engine program. Master the art of handling multiple engines, expanding your horizons in aviation. This endorsement enhances your proficiency, making you a versatile pilot. Prerequisites involve holding a CPL and completing additional flight hours with focused training on multi-engine aircraft.
                            </p>
                        </FadeInSectionDownToUp>
                        <FadeInSectionDownToUp>
                            <p className='text-3xl max-sm:text-2xl font-semibold pt-10'>
                                Instrument Rating
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                                Navigate the skies with precision through our Instrument Rating program. Gain expertise in flying solely by reference to instruments, a crucial skill for professional aviators. To enroll, you must already possess a CPL and accumulate specific flight hours, including cross-country and instrument flight time. Ground school complements your practical training, covering navigation aids, regulations, and advanced weather interpretation.
                            </p>
                            <p className='text-lg text-justify pt-5 max-sm:pt-5'>
                                At our aviation academy, we blend cutting-edge technology, experienced instructors, and rigorous training to shape you into a competent and confident commercial pilot. Discover the detailed requirements and excellence in ground school that set the foundation for your successful aviation career.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
                        <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-orange-500 font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>10 - 12 Months</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-orange-500 font-bold text-xl pb-3'>Eligibility</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>DGCA Class I Medical for issue of CPL License.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>10th +2 passed with Physics & Maths.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>17 years of age and above at the time of joining.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Adequate competency in the English Language.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div className='bg-black text-white py-5 pl-20 max-sm:pl-5 flex justify-start items-center rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-orange-500 font-bold text-xl pb-3'>Flying Training</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Single engine Flying – 185 Hrs (C-172,Tecnam, Diamond DA-40).</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Multi Engine Flying – 15 Hrs (Piper, Diamond DA-42, Tecnam).</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Simulator- 20 Hrs (Red Bird, Diamond).</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div className='bg-black text-white py-5 pl-20 max-sm:pl-5 flex justify-start items-center rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-orange-500 font-bold text-xl pb-3'>Theory</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Air Regulation.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Meteorology.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Navigation.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Technical General.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>RTR.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-orange-500 font-bold mr-2'>&#9992;</span><li className=''>Tech Spec.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='flex justify-center items-center py-10'>
                        <FadeInSectionDownToUp>
                            <a href='/private-pilot-license' className='text-orange-500 underline text-3xl'>Course Content</a>
                        </FadeInSectionDownToUp>
                    </div>
                    <FadeInSectionDownToUp>
                        <div className='grid grid-cols-3 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40'>
                            <button onClick={() => navigate('/contact')} className='bg-orange-500 text-white py-2 rounded-xl mx-2'>For Queries</button>
                            <button onClick={() => navigate('/contact')} className='bg-orange-500 text-white py-2 rounded-xl mx-2'>Apply Now</button>
                            <button className='bg-orange-500 text-white py-2 rounded-xl mx-2'><a href='https://drive.google.com/file/d/1QBDX5O5E_0BJBEy8klu0Co1IPc8C-lJr/view?usp=drive_link' target='blank'>Downoad</a></button>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
