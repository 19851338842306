export const LmsCourses = [
    {
      name: 'PPL',
      designation: 'Founder, Algochurn',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/PPL.webp',
      badge: 'Mentor',
      link: "/private-pilot-license",
      target: "",
    },
    {
      name: 'CPL/IR/ME',
      designation: "Founder, Sarah's Kitchen",
      image: 'https://ik.imagekit.io/mhcockpit1/Training/CPL.webp',
      badge: 'Mentor',
      link: "/commercial-pilot-license",
      target: "",
    },
    {
      name: 'Type Rating',
      designation: 'Software Engineer, Tech Corp',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/Type%20Rating.webp',
      badge: 'Mentor',
      link: "/typerating",
      target: "",
    },
    {
      name: 'ATPL',
      designation: 'Product Manager, Innovate Inc',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/ATPL%20GC.webp',
      badge: 'Mentor',
      link: "/airline-transport-pilot-license",
      target: "",
    },
    {
      name: 'License Conversion',
      designation: 'Data Scientist, DataWorks',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/license%20conversion.webp',
      badge: 'Mentor',
      link: "/dgca-license-conversion",
      target: "",
    },
    {
      name: 'Medical Requirement',
      designation: 'UX Designer, DesignHub',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/medical%20requirement%20dgca.webp',
      badge: 'Mentor',
      link: "/dgca-medical-requirements",
      target: "",
    },
    {
      name: 'Computer Number',
      designation: 'CTO, FutureTech',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/online%20test%20DGCA.webp',
      badge: 'Mentor',
      link: "/dgca-pilot-computer-number",
      target: "",
    },
    {
      name: 'BBA Aviation Management',
      designation: 'CEO, StartUp',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/bba%20Aviaiton.webp',
      link: "/bba-aviation",
      target: "",
    },
    {
      name: 'BSc. Aviation',
      designation: 'DevOps Engineer, CloudNet',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/bsc%20aviation.webp',
      badge: 'Something',
      link: "/bsc-aviation",
      target: "",
    },
    {
      name: 'B.Sc Aircraft Maintenance Science',
      designation: 'Marketing Manager, MarketGrowth',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/Aircraft%20Maintenance.webp',
      badge: 'Mentor',
      link: "/bsc-aircraft-maintenance-science",
      target: "",
    },
    {
      name: 'B.Sc- Aeronautical Science',
      designation: 'Frontend Developer, WebSolutions',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/B.Sc%20Aeronautical%20Science.webp',
      link: "/bsc-aeronautical-science",
      target: "",
    },
    {
      name: 'MBA- Aviation Management',
      designation: 'Backend Developer, ServerSecure',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/mba%201.webp',
      link: "/mba-aviation-management",
      target: "",
    },
    {
      name: 'MBA HR Marketing and Finance',
      designation: 'Full Stack Developer, FullStack',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/MBA.webp',
      badge: 'Badger',
      link: "/mba-hr-marketing-and-finance",
      target: "",
    },
    {
      name: 'Cabin Crew',
      designation: 'Database Administrator, DataSafe',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/Cabin%20Crew%202.webp',
      badge: 'Advocate',
      link: "/cabincrew",
      target: "",
    },
    {
      name: 'PG Diploma in AME',
      designation: 'Network Engineer, NetConnect',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/Aircraft%20Maintenance%20diploma.webp',
      link: "/PG-Diploma-in-AME",
      target: "",
    },
    {
      name: 'Ground/Flight Ops',
      designation: 'Security Analyst, SecureIT',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/airport%20operation.webp',
      link: "/diploma-in-airport-management",
      target: "",
    },
    {
      name: 'MH Intellect',
      designation: 'Systems Analyst, SysAnalyse',
      image: 'https://ik.imagekit.io/mhcockpit1/Training/MHA&I.webp',
      link: "https://mhintellect.com/",
      target: "blank",
    }
  ];
  